














import Vue from 'vue';

export default Vue.extend({
	data: () => ({
		email: '',
		password: ''
	}),
	methods: {
		submit() {
			this.$emit('login', { email: this.email, password: this.password });
		}
	},
	computed: {
		emailInputAttrs() {
			return {
				placeholder: 'Email Address',
				type: 'email',
				required: true
			};
		},
		passwordInputAttrs() {
			return {
				placeholder: 'Password',
				type: 'password',
				required: true
			};
		}
	}
});
